import reduce from "lodash/reduce";
import forEach from "lodash/forEach";
import isString from "lodash/isString";
import get from "lodash/get";
import map from "lodash/map";
import { OAuth2Client } from "@badgateway/oauth2-client";
import kebabCase from "lodash/kebabCase";
import keys from "lodash/keys";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";

let NerosRequestHeaders = {};
if (process.env.REACT_APP_AUTHORIZATION_HEADER) {
  NerosRequestHeaders = new Headers({
    Authorization: process.env.REACT_APP_AUTHORIZATION_HEADER,
  });
}

let WCRequestHeaders = {};
if (process.env.REACT_APP_WC_AUTHORIZATION_HEADER) {
  WCRequestHeaders = new Headers({
    Authorization: process.env.REACT_APP_WC_AUTHORIZATION_HEADER,
    "Content-Type": "application/json",
  });
}

let StripeRequestHeaders = {};
if (process.env.REACT_APP_STRIPE_API_KEY) {
  StripeRequestHeaders = new Headers({
    Authorization: process.env.REACT_APP_STRIPE_API_KEY,
    "Content-Type": "application/json",
  });
}

const stripeOrderIdRegEx = /(?:Commande|Order) ([0-9]+)(?:-([0-9]))?$/;
const helloAssoOrderIdRegEx = /(?:Commande Woocommerce )([0-9]+)$/;
const TIMEOUT = 20000;
let nonce;

const fetchApi = ({ baseUrl, headers, path, method = "GET", body }) => {
  const controller = new AbortController();
  const signal = controller.signal;

  const promise = new Promise(async (resolve, reject) => {
    const id = setTimeout(() => {
      controller.abort();

      const error = new Error("Connection timeout");
      error.customMessage = "Impossible de vous connecter...";
      reject(error);
    }, TIMEOUT);

    const response = await fetch(`${baseUrl}${path}`, {
      headers,
      method,
      body,
      signal,
    });

    clearTimeout(id);
    if (response.ok) {
      resolve(response.json());
    }

    if (response.status >= 400) {
      const error = new Error(`${response.status} HTTP error received.`);
      error.response = response;

      reject(error);
    }
  });

  promise.cancel = () => controller.abort();

  return promise;
};

const fetchNerosApi = async (path, headers, method, body, tryCount = 0) => {
  const builtHeaders = new Headers(NerosRequestHeaders);

  if (!nonce) {
    nonce = await fetchApi({
      baseUrl: process.env.REACT_APP_BASE_URL,
      path: process.env.REACT_APP_NONCE_PATH,
      headers: NerosRequestHeaders,
    });
  }

  if (nonce) {
    builtHeaders.append("X-WP-Nonce", nonce);
  }

  if (headers) {
    forEach(headers, (value, key) => builtHeaders.append(key, value));
  }

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return fetchApi({
    baseUrl,
    headers: builtHeaders,
    path,
    method,
    body,
  }).catch(async (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      if (tryCount === 0) {
        tryCount++;
        nonce = null;

        return fetchNerosApi(path, headers, method, body, tryCount);
      }

      document.location = `https://www.cisag.org/wp-login.php?redirect_to=${document.location}`;
    }
  });
};

const fetchWCApi = (path, method, body) =>
  fetchApi({
    baseUrl: process.env.REACT_APP_WC_API_BASE_URL,
    headers: WCRequestHeaders,
    path,
    method,
    body,
  });

const fetchStripeApi = (path, method, body) =>
  fetchApi({
    baseUrl: process.env.REACT_APP_STRIPE_API_BASE_URL,
    headers: StripeRequestHeaders,
    path,
    method,
    body,
  });

const HELLOASSO_CLIENT_ID = "862ac354ff1240178c19dcc867906f0c";
const HELLOASSO_SECRET = "AtpyMghQx9kBHxaCXiuk7jXn3ywKHdQR";

let helloAssoToken;

const getHelloAssoToken = async () => {
  if (helloAssoToken && helloAssoToken.expiresAt > Date.now()) {
    return helloAssoToken.accessToken;
  }

  const client = new OAuth2Client({
    server: "https://api.helloasso.com",
    clientId: HELLOASSO_CLIENT_ID,
    clientSecret: HELLOASSO_SECRET,
    tokenEndpoint: "/oauth2/token",
    authorizationEndpoint: "/oauth2/authorize",
    discoveryEndpoint: null,
  });

  if (helloAssoToken) {
    const token = await client.refreshToken(helloAssoToken.refresh_token);

    helloAssoToken = token;

    return token.accessToken;
  }

  const token = await client.clientCredentials();

  helloAssoToken = token;

  return token.accessToken;
};

export const fetchMembers = (season, activity, quarter) =>
  isString(activity)
    ? fetchNerosApi(
        `${season}/membersByCourse/${activity}${
          quarter ? `?quarter=${quarter}` : ""
        }`
      )
    : fetchNerosApi(`${season}/members`);
export const fetchMember = (id) => fetchNerosApi(`members/${id}`);
export const fetchMembersByTemporaryActivity = (type, season) =>
  fetchNerosApi(`${season}/membersByTemporaryActivity/${type}`);
export const updateActivityItem = ({ id, locked, time, numberOfDays }) =>
  fetchNerosApi(
    `activityItems/${id}${
      time && numberOfDays ? `?time=${time}&numberOfDays=${numberOfDays}` : ""
    }`,
    { "Content-Type": "application/json" },
    "POST",
    JSON.stringify({ locked })
  );
export const updateMember = ({ id, courseIds, practice }) =>
  fetchNerosApi(
    `members/${id}${practice ? "?practice=1" : ""}`,
    { "Content-Type": "application/json" },
    "PUT",
    JSON.stringify({ courses: courseIds })
  );

export const setMemberDocument = ({ id, key, handDelivered, index }) =>
  fetchNerosApi(
    `members/${id}/documents/${key}`,
    { "Content-Type": "application/json" },
    "POST",
    JSON.stringify({ handDelivered, index })
  );

export const setMemberCard = ({ id, requested, name }) =>
  fetchNerosApi(
    `members/${id}/card/${name}`,
    { "Content-Type": "application/json" },
    "POST",
    JSON.stringify({ requested })
  );

export const setOrderAccessory = ({ id, item, handDelivered }) =>
  fetchNerosApi(
    `orders/${id}/accessories/${item.id}`,
    { "Content-Type": "application/json" },
    "POST",
    JSON.stringify({ handDelivered })
  );

export const fetchCourses = (season, productId) =>
  fetchNerosApi(`${season}/coursesByProduct/${productId}`);

export const fetchOrders = (orderIds) =>
  fetchNerosApi(`orders?ids=${orderIds.join(",")}`);

export const fetchGeneralStats = (season) =>
  fetchNerosApi(`${season}/stats/general`);
export const fetchActivitiesStats = (season) =>
  fetchNerosApi(`${season}/stats/activities`);

export const fetchOrder = (id) => fetchWCApi(`orders/${id}`);
export const updateOrder = ({ id, status }) =>
  fetchWCApi(
    `orders/${id}`,
    "PUT",
    JSON.stringify({
      status,
      payment_method: "cheque",
      payment_method_title: "Chèque, chèques vacances ou espèces",
    })
  );

export const fetchSalesReport = (season, type) =>
  fetchNerosApi(`${season}/stats/sales/${type}`);

let cachedOrders = [];
const payoutsByDate = {};
let currentOrderPageIndex = 1;
let hasMorePages = true;
const NUMBER_OF_MONTHS_BY_PAGE = 6;

export const fetchHelloAssoPayouts = async ({ pageParam = 1 }) => {
  const headers = new Headers({
    Authorization: `Bearer ${await getHelloAssoToken()}`,
  });

  let allOrders = cachedOrders;

  while (
    hasMorePages &&
    Object.keys(payoutsByDate).length < NUMBER_OF_MONTHS_BY_PAGE * pageParam + 1
  ) {
    const response = await fetchApi({
      baseUrl:
        "https://api.helloasso.com/v5/organizations/club-intercommunal-des-sports-acrobatiques-et-gymniques",
      headers,
      path: `/orders?pageIndex=${currentOrderPageIndex}&pageSize=100`,
    });

    allOrders = allOrders.concat(response.data);
    hasMorePages =
      get(response, "pagination.totalPages") > currentOrderPageIndex;

    forEach(response.data, (order) => {
      forEach(order.payments, (payment) => {
        if (payment.cashOutState === "CashedOut") {
          const payoutDate = new Date(payment.cashOutDate).toLocaleString(
            "default",
            {
              day: "numeric",
              month: "long",
              year: "numeric",
            }
          );

          if (!payoutsByDate[payoutDate]) {
            payoutsByDate[payoutDate] = {
              amount: 0,
              date: new Date(payment.cashOutDate).getTime() / 1000,
              orders: [],
              id: kebabCase(payoutDate),
            };
          }
          payoutsByDate[payoutDate] = {
            ...payoutsByDate[payoutDate],
            amount: payoutsByDate[payoutDate].amount + payment.amount,
            orders: [...payoutsByDate[payoutDate].orders, order],
          };
        }
      });
    });

    if (hasMorePages) {
      currentOrderPageIndex++;
    }
  }

  cachedOrders = allOrders;

  const paginatedPayoutsByDate = {};
  const payoutDates = Object.keys(payoutsByDate);
  const start = (pageParam - 1) * NUMBER_OF_MONTHS_BY_PAGE;
  const end = start + NUMBER_OF_MONTHS_BY_PAGE;

  payoutDates.slice(start, end).forEach((payoutDate) => {
    paginatedPayoutsByDate[payoutDate] = payoutsByDate[payoutDate];
  });

  return {
    data: Object.values(paginatedPayoutsByDate),
    hasMore: end < payoutDates.length || hasMorePages,
    nextPage: pageParam + 1,
  };
};

export const fetchStripePayouts = ({ pageParam }) =>
  fetchStripeApi(
    `payouts?limit=50${pageParam ? `&starting_after=${pageParam}` : ""}`,
    "GET"
  );

export const fetchStripeCharge = ({ id }) =>
  fetchStripeApi(`charges/${id}`, "GET");

export const fetchHelloAssoPayoutDetail = async ({ payout }) => {
  const ordersById = reduce(
    payout.orders,
    (ordersByIdAccumulator, order) => {
      const orderIdMatch = get(order, "items[0].name", "").match(
        helloAssoOrderIdRegEx
      );

      ordersByIdAccumulator[parseInt(get(orderIdMatch, 1), 10)] = order;

      return ordersByIdAccumulator;
    },
    {}
  );

  const orderIds = keys(ordersById);
  const orderDetails = await fetchOrders(orderIds);

  return reduce(
    ordersById,
    (allocation, order, orderId) => {
      const type = orderDetails[orderId] || "other";

      const amount = parseInt(get(order, "amount.total", 0), 10);

      if (!allocation[type]) {
        allocation[type] = { raw: 0, net: 0, fee: 0 };
      }

      allocation[type].raw += amount;
      allocation[type].net = allocation[type].raw;

      return allocation;
    },
    {}
  );
};

export const fetchStripePayoutDetail = async ({ id }) => {
  const transactions = await fetchStripeApi(
    `transfers/${id}/transactions?limit=100`,
    "GET"
  );

  const transactionOrders = await Promise.all(
    map(get(transactions, "data"), async (transaction) => {
      let charge;

      if (transaction.type === "payout") return {};

      if (transaction.amount < 0 && transaction.type === "charge") {
        charge = await fetchStripeCharge({ id: transaction.id });
      } else if (transaction.amount < 0) {
        return { transaction };
      }

      const orderParsing = get(charge || transaction, "description", "").match(
        stripeOrderIdRegEx
      );
      let orderId = parseInt(get(orderParsing, 1), 10);

      if (!orderId) {
        return { transaction };
      }

      const subOrderId = get(orderParsing, 2);

      if (subOrderId) {
        orderId += parseInt(subOrderId, 10);
      }

      return { transaction, orderId };
    })
  );

  const orderIds = compact(map(transactionOrders, ({ orderId }) => orderId));
  const orders = !isEmpty(orderIds) ? await fetchOrders(orderIds) : [];

  return reduce(
    transactionOrders,
    (allocation, { transaction, orderId }) => {
      if (!transaction) {
        return allocation;
      }

      const type = orders[orderId] || "other";

      if (!allocation[type]) {
        allocation[type] = { raw: 0, net: 0, fee: 0 };
      }

      if (transaction.type !== "stripe_fee") {
        allocation[type].raw += parseInt(transaction.amount, 10);
      }

      allocation[type].net += parseInt(transaction.net, 10);
      allocation[type].fee += parseInt(transaction.fee, 10);

      return allocation;
    },
    {}
  );
};

export const fetchCurrentUser = () => fetchNerosApi("users/current");
