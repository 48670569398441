import React, { useCallback, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import MaterialDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Typography,
  ListSubheader,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import AccessibilityNewRoundedIcon from "@mui/icons-material/AccessibilityNewRounded";
import BeachAccessRoundedIcon from "@mui/icons-material/BeachAccessRounded";
import SportsHandballRoundedIcon from "@mui/icons-material/SportsHandballRounded";
import ChildFriendlyRoundedIcon from "@mui/icons-material/ChildFriendlyRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import { Link, useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { useHasMasterRights } from "./utils/useHasMasterRights";
import { getSeasonList, isNurseryQuarterEnable } from "./utils/season";
import last from "lodash/last";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  title: {
    color: theme.palette.text.primary,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  formControl: {
    margin: theme.spacing(2, 1, 1),
    minWidth: 240,
  },
}));

const seasonList = getSeasonList();

export const Drawer = () => {
  const classes = useStyles();
  const [state, setState] = useState(false);

  const history = useHistory();
  const { pathname } = useLocation();
  const {
    params: { season: seasonFromUrl },
  } = useRouteMatch("/:season?");

  const season = seasonList.includes(seasonFromUrl)
    ? seasonFromUrl
    : last(seasonList);

  const hasMasterRights = useHasMasterRights(true);
  const hasEducatorPlusRights = useHasMasterRights(false, true);

  const redirectToSeletedSeason = useCallback(
    (event) => {
      history.push(pathname.replace(season, event.target.value));
    },
    [pathname, history, season]
  );

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className={classes.drawerHeader}>
        <IconButton size="large">
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListSubheader>
          <Typography variant="h6" className={classes.title}>
            NEROS
          </Typography>
          <Typography variant="subtitle2">Gestion des inscriptions</Typography>
        </ListSubheader>
      </List>
      <Divider />
      {hasEducatorPlusRights && (
        <FormControl className={classes.formControl}>
          <InputLabel id="season">Saison</InputLabel>
          <Select
            value={season}
            onChange={redirectToSeletedSeason}
            labelId="season"
            label="saison"
            id="seasonSelect"
          >
            {seasonList.map((season) => (
              <MenuItem key={season} value={season}>
                {season} - {season + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <List>
        {hasEducatorPlusRights && (
          <ListItem button component={Link} to={`/${season}/dashboard/general`}>
            <ListItemIcon>
              <DashboardRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        )}
        <ListItem button component={Link} to={`/${season}/membres`}>
          <ListItemIcon>
            <PeopleRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Adhérents" />
        </ListItem>
        <ListItem button component={Link} to={`/${season}/cours/trampoline`}>
          <ListItemIcon>
            <AccessibilityNewRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Cours" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to={`/${season}/assistantes-maternelles${
            isNurseryQuarterEnable(season) ? "/t4" : ""
          }`}
        >
          <ListItemIcon>
            <ChildFriendlyRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Assistantes maternelles" />
        </ListItem>
        <ListItem button component={Link} to={`/${season}/stages/trampocamp`}>
          <ListItemIcon>
            <BeachAccessRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Stage" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to={`/${season}/activites-ponctuelles`}
        >
          <ListItemIcon>
            <SportsHandballRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Activités ponctuelles" />
        </ListItem>
        {hasMasterRights && (
          <ListItem button component={Link} to={`/comptabilite/helloasso`}>
            <ListItemIcon>
              <AccountBalanceRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Comptabilité" />
          </ListItem>
        )}
      </List>
    </div>
  );

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        size="large"
      >
        <MenuIcon />
      </IconButton>
      <MaterialDrawer anchor="left" open={state} onClose={toggleDrawer(false)}>
        {list()}
      </MaterialDrawer>
    </div>
  );
};
