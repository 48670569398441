import React, { useEffect, Suspense } from "react";
import { useParams, Redirect } from "react-router-dom";
import { AccountingTable } from "../components/accounting/AccountingTable";
import { Loader } from "../components/Loader";
import { useHasMasterRights } from "../utils/useHasMasterRights";
import values from "lodash/values";

const accountingMap = {
  helloasso: {
    label: "Virements CB HelloAsso",
    to: "/comptabilite/helloasso",
  },
  stripe: {
    label: "Virements CB Stripe",
    to: "/comptabilite/stripe",
  },
};

export const Accounting = ({ setPageTitle, setMoreMenu }) => {
  let { platform } = useParams();

  const hasMasterRights = useHasMasterRights(true);

  useEffect(() => {
    setPageTitle(accountingMap[platform].label);
    setMoreMenu(values(accountingMap));
  }, [setPageTitle, setMoreMenu, platform]);

  if (hasMasterRights === undefined) {
    return <Loader />;
  }

  return hasMasterRights ? (
    <Suspense fallback={<Loader />}>
      <AccountingTable platform={platform} />
    </Suspense>
  ) : (
    <Redirect to="/" />
  );
};
