import React, { useEffect } from "react";
import map from "lodash/map";
import { useParams, withRouter } from "react-router-dom";
import { GeneralDashboard } from "../components/dashboard/GeneralDashboard";
import { useHasMasterRights } from "../utils/useHasMasterRights";
import { FinancialReport } from "../components/dashboard/FinancialReport";
import { DemographicReport } from "../components/dashboard/DemographicReport";

const reportsMap = {
  general: {
    label: "Vue globale",
    to: "/:season/dashboard/general",
  },
  finances: {
    label: "Rapport financier",
    to: "/:season/dashboard/finances",
  },
  demographie: {
    label: "Rapport démographique",
    to: "/:season/dashboard/demographie",
  },
};

export const Dashboard = withRouter(
  ({ setPageTitle, setMoreMenu, history }) => {
    const { season, type } = useParams();
    const hasEducatorPlusRights = useHasMasterRights(false, true);

    useEffect(() => {
      if (!hasEducatorPlusRights) {
        history.replace(`/${season}/cours/trampoline`);
      }
    }, [history, hasEducatorPlusRights, season]);

    useEffect(() => {
      setPageTitle(reportsMap[type].label);
      setMoreMenu(
        map(reportsMap, (reportsMenu) => ({
          ...reportsMenu,
          to: reportsMenu.to.replace(":season", season),
        }))
      );
    }, [setPageTitle, setMoreMenu, season, type]);

    if (!hasEducatorPlusRights) {
      return null;
    }

    if (type === "finances") {
      return <FinancialReport />;
    }

    if (type === "demographie") {
      return <DemographicReport />;
    }

    return <GeneralDashboard />;
  }
);
