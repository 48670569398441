import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard";
import { Courses } from "./pages/Courses";
import { Members } from "./pages/Members";
import { TemporaryActivities } from "./pages/TemporaryActivities";
import { Nursery } from "./pages/Nursery";
import { Accounting } from "./pages/Accounting";
import { getCurrentSeason } from "./utils/season";
import { HolidayCamp } from "./pages/HolydayCamp";
import { Schedule } from "./pages/Schedule";

const currentSeason = getCurrentSeason();

export const Routes = ({
  setPageTitle,
  setMoreMenu,
  enableSearch,
  setFilters,
  searchValue,
  setHeaderVisible,
  error,
  setError,
  resetErrorBoundary,
}) => (
  <Switch>
    <Route exact path="/:season/schedule">
      <Schedule setHeaderVisible={setHeaderVisible} />
    </Route>
    <Route exact path="/:season/dashboard/:type">
      <Dashboard setPageTitle={setPageTitle} setMoreMenu={setMoreMenu} />
    </Route>
    <Route path="/:season/cours/:activity">
      <Courses setMoreMenu={setMoreMenu} setPageTitle={setPageTitle} />
    </Route>
    <Route path="/:season/membres">
      <Members
        setPageTitle={setPageTitle}
        enableSearch={enableSearch}
        setFilters={setFilters}
        searchValue={searchValue}
      />
    </Route>
    <Route path="/:season/activites-ponctuelles">
      <TemporaryActivities setPageTitle={setPageTitle} />
    </Route>
    <Route path="/:season/stages/:activity">
      <HolidayCamp
        setMoreMenu={setMoreMenu}
        setPageTitle={setPageTitle}
        enableSearch={enableSearch}
        searchValue={searchValue}
      />
    </Route>
    <Route path="/:season/assistantes-maternelles/:quarter">
      <Nursery setMoreMenu={setMoreMenu} setPageTitle={setPageTitle} />
    </Route>
    <Route path="/:season/assistantes-maternelles">
      <Nursery setMoreMenu={setMoreMenu} setPageTitle={setPageTitle} />
    </Route>
    <Route path="/comptabilite/:platform">
      <Accounting setPageTitle={setPageTitle} setMoreMenu={setMoreMenu} />
    </Route>
    <Route path="*">
      <Redirect to={`/${currentSeason}/dashboard/general`} />
    </Route>
  </Switch>
);
