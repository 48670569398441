import { useInfiniteQuery } from "react-query";
import { fetchStripePayouts } from "./apiFetcher";
import last from "lodash/last";

export default function useInfiniteStripePayouts() {
  return useInfiniteQuery("stripePayouts", fetchStripePayouts, {
    getNextPageParam: (lastPage, pages) =>
      lastPage.has_more && last(lastPage.data).id,
  });
}
