import React, { Fragment, useRef } from "react";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Message } from "../Message";
import useIntersectionObserver from "../../utils/useIntersectionObserver";
import { PayoutRow } from "./PayoutRow";
import useInfiniteHelloAssoPayouts from "../../utils/useInfiniteHelloAssoPayouts";
import useInfiniteStripePayouts from "../../utils/useInfiniteStripePayouts";

const platformHook = {
  helloasso: useInfiniteHelloAssoPayouts,
  stripe: useInfiniteStripePayouts,
};

export const AccountingTable = ({ platform }) => {
  const {
    data: payouts,
    fetchNextPage,
    hasNextPage,
  } = platformHook[platform]();

  const loadMoreRef = useRef();

  useIntersectionObserver({
    threshold: 0.1,
    rootMargin: "500px",
    target: loadMoreRef,
    onIntersect: () => {
      fetchNextPage();
    },
    enabled: hasNextPage,
  });

  if (!payouts) {
    return <Message>Aucun virement...</Message>;
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Date</TableCell>
            <TableCell align="right">Montant</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payouts.pages.map((group, index) => (
            <Fragment key={index}>
              {group.data.map((payout) => (
                <PayoutRow
                  key={payout.id}
                  payout={payout}
                  platform={platform}
                />
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <Box justifyContent="center" display="flex" p={2} ref={loadMoreRef}>
        {hasNextPage && <CircularProgress color="inherit" />}
      </Box>
    </>
  );
};
