import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import map from "lodash/map";
import compact from "lodash/compact";
import React from "react";
import { formatAmount } from "../../utils/accounting";
import useHelloAssoPayoutAllocation from "../../utils/useHelloAssoPayoutAllocation";
import useStripePayoutAllocation from "../../utils/useStripePayoutAllocation";

const getAllocationLabel = (type) => {
  switch (type) {
    case "subscription":
      return "Cotisation";
    case "training":
      return "Stage";
    case "nursery":
      return "Ass. Mat.";
    default:
      return "Autre";
  }
};

const platformHook = {
  helloasso: useHelloAssoPayoutAllocation,
  stripe: useStripePayoutAllocation,
};

export const PayoutTable = ({ payout, platform }) => {
  const { data: allocation } = platformHook[platform](payout);

  return (
    <>
      <Typography variant="subtitle1" gutterBottom component="div">
        Detail du virement
      </Typography>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">Brut</TableCell>
            <TableCell align="right">Net</TableCell>
            <TableCell align="right">Frais</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {compact(
            map(
              allocation,
              (amounts, type) =>
                amounts.raw && (
                  <TableRow key={type}>
                    <TableCell component="th" scope="row">
                      {getAllocationLabel(type)}
                    </TableCell>
                    <TableCell align="right">
                      {formatAmount(amounts.raw)}
                    </TableCell>
                    <TableCell align="right">
                      {formatAmount(amounts.net)}
                    </TableCell>
                    <TableCell align="right">
                      {formatAmount(amounts.fee)}
                    </TableCell>
                  </TableRow>
                )
            )
          )}
        </TableBody>
      </Table>
    </>
  );
};
