import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchMembers } from "../../utils/apiFetcher";
import filter from "lodash/filter";
import size from "lodash/size";
import map from "lodash/map";
import { DemographicTable } from "./DemographicTable";

const calculateAge = (birthDate) => {
  const [day, month, year] = birthDate.split("/");
  const birthJSDate = new Date(year, month - 1, day);
  const ageDifferenceTime = Date.now() - birthJSDate.getTime();
  const ageDate = new Date(ageDifferenceTime);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const lyonPostcodes = [
  "69000",
  "69001",
  "69002",
  "69003",
  "69004",
  "69005",
  "69006",
  "69007",
  "69008",
  "69009",
];

export const MembersDemographicReport = () => {
  const { season } = useParams();
  const {
    data: { sexeRows, ageRows, villeRows, totalMembers },
  } = useQuery(["membersDemographic", season], async () => {
    const members = await fetchMembers(season);

    const membersWithAge = map(members, (member) => ({
      ...member,
      age: calculateAge(member["Date De Naissance"]),
    }));

    const sexeRows = [
      {
        name: "Femmes",
        sexe: filter(membersWithAge, (member) => member.Sexe === "Féminin")
          .length,
      },
      {
        name: "Hommes",
        sexe: filter(membersWithAge, (member) => member.Sexe === "Masculin")
          .length,
      },
    ];

    const ageRows = [
      {
        name: "Moins de 7 ans",
        sexe: filter(membersWithAge, (member) => member.age <= 6).length,
      },
      {
        name: "Entre 7 et 18 ans",
        sexe: filter(
          membersWithAge,
          (member) => member.age > 6 && member.age < 18
        ).length,
      },
      {
        name: "Plus de 18 ans",
        sexe: filter(membersWithAge, (member) => member.age >= 18).length,
      },
    ];

    const villeRows = [
      {
        name: "Oullins-Pierre-Bénite",
        sexe: filter(
          membersWithAge,
          (member) =>
            member.parent1_postcode === "69600" ||
            member.parent1_postcode === "69310"
        ).length,
      },
      {
        name: "Saint-Genis-Laval",
        sexe: filter(
          membersWithAge,
          (member) => member.parent1_postcode === "69230"
        ).length,
      },
      {
        name: "Sainte-Foy-lès-Lyon",
        sexe: filter(
          membersWithAge,
          (member) => member.parent1_postcode === "69110"
        ).length,
      },
      {
        name: "Lyon",
        sexe: filter(membersWithAge, (member) =>
          lyonPostcodes.includes(member.parent1_postcode)
        ).length,
      },
      {
        name: "Autres villes",
        sexe: filter(
          membersWithAge,
          (member) =>
            !["69600", "69310", "69230", "69110", ...lyonPostcodes].includes(
              member.parent1_postcode
            )
        ).length,
      },
    ];

    const totalMembers = size(membersWithAge);

    return {
      sexeRows,
      ageRows,
      villeRows,
      totalMembers,
    };
  });

  return (
    <>
      <DemographicTable
        title="Répartition par sexe"
        rows={sexeRows}
        totalMembers={totalMembers}
      />
      <DemographicTable
        title="Répartition par âge"
        rows={ageRows}
        totalMembers={totalMembers}
      />
      <DemographicTable
        title="Répartition par ville"
        rows={villeRows}
        totalMembers={totalMembers}
      />
    </>
  );
};
