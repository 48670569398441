import React, { Suspense } from "react";
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { formatAmount, formatTransactionDate } from "../../utils/accounting";
import { PayoutTable } from "./PayoutTable";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export const PayoutRow = ({ payout, platform }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {formatTransactionDate(payout.date)}
        </TableCell>
        <TableCell align="right">{formatAmount(payout.amount)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Suspense
              fallback={
                <Box justifyContent="center" display="flex" m={2}>
                  <CircularProgress size={30} color="inherit" />
                </Box>
              }
            >
              <Box m={1}>
                {open ? (
                  <PayoutTable payout={payout} platform={platform} />
                ) : null}
              </Box>
            </Suspense>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
